










import PageTemplate from "@/components/PageTemplate.vue";
import TermsAndPhrases from "@/components/TermsAndPhrases/TermsAndPhrases.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    TermsAndPhrases,
  },
})
export default class BlockedTermsAndPhrases extends Vue {
  RouteName = RouteName;
}
