

















import { Vue, Component, Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import PageHeader from "./PageHeader.vue";

@Component({
  components: { PageHeader },
})
export default class PageTemplate extends Vue {
  @Prop() title!: string;
  @Prop() description!: string;
  @Prop() to?: RawLocation;
}
