















































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { AddBannedWordRequest, BadWord, Word } from "./store/types";
import { csvToArray, guessFileType } from "@/helpers";
import { RouteName } from "@/router/types";

@Component
export default class TermsAndPhrases extends Vue {
  @Ref("csvInput") csvInput!: HTMLInputElement;
  @Ref("csvInputTextField") csvInputTextField!: HTMLInputElement;

  @Getter("badWords/getAllBannedWords")
  readonly getAllBannedWords!: Word[];
  @Getter("badWords/getBadWordsError")
  readonly getBadWordsError!: string;
  @Action("badWords/allBannedWordsByTanant")
  allBannedWordsByTanant!: () => void;
  @Action("badWords/deleteBadWord")
  deleteBadWordRequest!: (word: string) => void;
  @Action("badWords/addBadWord")
  addBadWordRequest!: (data: AddBannedWordRequest) => void;

  RouteName = RouteName;

  input = "";
  isAddActive = false;
  search = "";

  get headers() {
    return [
      { text: this.$t("blockedTerms.termsAndPhrases"), value: "badWord" },
      {
        text: this.$t("dataTable.actions"),
        value: "actions",
        sortable: false,
        width: "100px",
        align: "center",
        cellClass: "text-no-wrap",
      },
    ];
  }

  get uploadHeaders() {
    return [
      {
        text: this.$t("blockedTerms.termsAndPhrases"),
        value: "badWord",
        sortable: false,
      },
    ];
  }

  csv: string[][] = [];
  csvFile: File | null = null;
  csvHasHeaders = true;
  csvUploadError = "";

  get disabled() {
    return !this.input || this.input.length < 3;
  }

  created() {
    this.allBannedWordsByTanant();
  }

  async addBadWord() {
    const inputValue: string[] = this.input
      .split(",")
      .map((element) => element.trim())
      .filter((element) => element !== "");
    if (!inputValue || inputValue.length === 0) return;
    if (
      inputValue.every((value) =>
        this.getAllBannedWords[0].map.all.includes(value)
      )
    ) {
      this.$toast.error(this.$tc(`Bad word already exists`), {
        timeout: 2000,
      });
    } else {
      await this.addBadWordRequest({
        language: "all",
        badWord: inputValue,
      });
    }
    this.input = "";
    await this.allBannedWordsByTanant();
  }

  close() {
    this.$emit("close");
  }

  addActive() {
    this.isAddActive = true;
  }

  get wordsLength() {
    if (this.getBadWordsError || !this.getAllBannedWords[0]) {
      return 0;
    } else {
      return this.getAllBannedWords[0].map?.all?.length ?? 0;
    }
  }

  get words() {
    if (this.getAllBannedWords[0]) {
      const wordsArray = this.getAllBannedWords[0].map["all"]
        ? this.getAllBannedWords[0].map["all"].map((word: string) => {
            return {
              badWord: word,
            };
          })
        : [];
      return wordsArray;
    } else return [];
  }

  deleteBadWord(word: BadWord) {
    this.deleteBadWordRequest(word.badWord);
    this.allBannedWordsByTanant();
  }

  // CSV Methods
  toggleCsvHasHeaders() {
    this.csvHasHeaders = !this.csvHasHeaders;

    if (this.csvFile) {
      this.readCsvFile(this.csvFile);
    }
  }

  resetCsvUploadField() {
    this.csv = [];
    this.csvInput.value = "";
    this.csvInputTextField.value = "";
    this.csvFile = null;
    this.csvUploadError = "";
  }

  onCSVFileUpload(e: Event) {
    // Returns File object, if it exists and passes validation.
    const file = ((e: Event): File | void => {
      if (!e.target) return;

      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files || !files.length) {
        console.warn("No files found");
        this.resetCsvUploadField();
        return;
      }

      const acceptedFileTypes: string[] = target.accept
        .split(",")
        .map((fileType) => fileType.trim());
      const fileType = guessFileType(files[0].name);

      if (!(fileType && acceptedFileTypes.includes(fileType))) {
        this.csvUploadError = this.$tc("blockedTerms.invalidFileType");
        this.csv = [];
        this.csvFile = null;
        console.warn(`Invalid filetype, expected ${target.accept}`);
        return;
      }

      // TODO: Maybe make CSV headers required and define the headers we are expecting so we can throw a nice UI error.
      this.csvUploadError = "";

      return files[0];
    })(e);

    if (file) {
      this.csvFile = file;
      this.readCsvFile(file);
    }
  }

  readCsvFile(file: File) {
    // Convert CSV into readable format
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const csvData = csvToArray(text);
      if (csvData) {
        this.csv = csvData;
      } else {
        this.csv = [];
        this.csvUploadError = this.$tc("blockedTerms.invalidFileFormat");
      }
    };
    reader.readAsText(file);
  }

  submitCsv() {
    if (!this.pendingBadWords.length) return;

    this.addBadWordRequest({
      language: "all",
      badWord: this.pendingBadWords,
    });

    this.resetCsvUploadField();
  }

  get pendingBadWords(): string[] {
    const { csv } = this;
    const listOfBadWords = Array<string>();

    if (!csv.length) return [];

    const headers = csv[0];
    // TODO: Define a sample.csv or always expect certain headers. We don't need to allow dynamic column names for one / two columns.
    // For now we expect badWord or treat the first column as a badWord column
    const badWordIndex =
      headers.findIndex(
        (header) => header.toLowerCase().trim() === "blocked term"
      ) || 0;

    if (csv) {
      let index = this.csvHasHeaders ? 1 : 0; // Skip header row.
      for (index; index < csv.length; index++) {
        const csvRow = csv[index];
        const badword = csvRow[badWordIndex];
        if (badword) {
          listOfBadWords.push(csvRow[badWordIndex]);
        }
      }
    }

    if (!listOfBadWords.length) {
      this.csvUploadError = this.$tc("blockedTerms.invalidCsvFile");
    }

    return listOfBadWords;
  }

  get badWordsCsvPreviewList(): BadWord[] {
    return this.pendingBadWords.map((word) => ({ badWord: word }));
  }
}
