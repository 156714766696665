



























import { Component, Prop, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component
export default class PageHeader extends Vue {
  @Prop() readonly title!: string;
  @Prop() readonly subtitle!: string;
  @Prop() readonly to?: RawLocation | undefined;
}
